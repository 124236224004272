import { useEffect, useRef } from "react";
import styled from "styled-components";
import { Tmapv2 } from "./t-map.types";
import {
  createMapMarker,
  getRandomRouteColor,
  ROUTE_COLORS,
  RouteColor,
} from "../../lib/map";
import { ClusterInternalDataWithChargeAndCluster } from "../../containers/cluster/utils";

export default function ClusterPolyLineContainer({
  activePlainClusteringData,
}: {
  activePlainClusteringData: Record<
    string,
    ClusterInternalDataWithChargeAndCluster[]
  >;
}) {
  const mapRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!activePlainClusteringData || !mapRef.current) return;

    const tmapV2 = window.Tmapv2;
    const map = new tmapV2.Map("polyline_map_div", {
      center: new tmapV2.LatLng(37.5648045, 126.98512028), // 기본 중심점 설정
      width: "100%",
      height: "800px",
      zoom: 10,
      zoomControl: true,
      scrollwheel: true,
      httpsMode: true,
    });

    const tempMapElements: Record<
      string,
      {
        markers: Tmapv2.Marker[];
        polylines: Tmapv2.Polyline[];
        visible: boolean;
        clusterKey: string;
        color: string;
      }
    > = {};

    const uniqueColorSet = new Set<RouteColor>();
    const entries = Object.entries(activePlainClusteringData);

    entries.forEach(([clusterKey, clusterItems]) => {
      // 각 클러스터마다 랜덤 색상 할당
      let color = getRandomRouteColor();
      let entryCount = 0;

      while (uniqueColorSet.has(color) && entryCount < ROUTE_COLORS.length) {
        color = getRandomRouteColor();
        entryCount++;
      }
      uniqueColorSet.add(color);

      tempMapElements[clusterKey] = {
        markers: [],
        polylines: [],
        visible: true,
        clusterKey,
        color: color.hex,
      };

      // 각 클러스터의 수신자 위치를 수집
      const receiverPoints = clusterItems
        .filter((item) => item.receiverLatitude && item.receiverLongitude)
        .map((item) => {
          const lat = parseFloat(item.receiverLatitude);
          const lng = parseFloat(item.receiverLongitude);
          return new tmapV2.LatLng(lat, lng);
        });

      if (receiverPoints.length > 0) {
        // 각 수신자 위치에 마커 표시
        receiverPoints.forEach((point, index) => {
          const marker = new tmapV2.Marker({
            position: point,
            iconHTML: createMapMarker("", color.hex),
            iconSize: new tmapV2.Size(24, 38),
            map: map,
            title: clusterItems[index]?.client_name || `위치 ${index + 1}`,
          });

          tempMapElements[clusterKey].markers.push(marker);
        });

        // 폴리라인으로 위치 연결
        // const polyline = new tmapV2.Polyline({
        //   path: receiverPoints,
        //   strokeColor: color.hex,
        //   strokeWeight: 4,
        //   map: map,
        // });
        // tempMapElements[clusterKey].polylines.push(polyline);
      }
    });
  }, [activePlainClusteringData]);

  return (
    <MapWrapper>
      <MapContainer id="polyline_map_div" ref={mapRef} />
    </MapWrapper>
  );
}

const MapWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const MapContainer = styled.div`
  width: 100%;
  height: 400px;
`;
